
.top-bar {
  padding: 0 30px;
  z-index: 4;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  background-color: $color-black;
  color: white;
  font-size: 14px;

  .top-bar-left {
    text-align: left;
    padding: 0 15px;
    padding-left: 0; // let logo handle the padding!
  }

  .top-bar-right {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    align-items: center;
    padding: 0;

    div {
      padding: 0 20px;
      border-right: 1px solid rgba(255, 255, 255, .2);
      a { color: white; }

      &:last-child {
        padding-right: 0;
        border: none;
      }

      &.with-dropdown {
        border: none;
        line-height: 42px; // height of top bar
        height: 42px;
        padding: 0;
        div {
          padding: 0;
          a {
            padding: 0 20px;
            border-right: 1px solid rgba(255, 255, 255, .2);
          }
        }
      }

    }

  }
}
