@mixin ellipsis-overflow($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

.clamped-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.centered {
  text-align: center;
}

.full-width {
  width: 100%;
}

.flex-out {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-center-center {
  @extend .flex-center;
  justify-content: center;
}

.pseudo-centered {
  width: fit-content;
  margin: auto;
}
