
.delivery-for {
  font-weight: bold;
  b { font-weight: bold; }
  font-size: 18px;
}

.delivery-window {
  font-weight: 300;
  font-size: 12px;
}
