.foodmaker-location {
  font-size: 13px;
  font-weight: 100;
  letter-spacing: .05em;
  padding-top: 3px;

  svg {
    padding-right: 5px;
  }
}
