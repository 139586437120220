@import 'src/styles/universal/variables';
@import 'src/styles/universal/responsive';

.product-breadcrumbs {
  font-size: 12px;
  color: $gray-color;

  padding: 15px;
  @include breakpoint(md) {
    padding: 15px 30px 5px 30px;
  }

  .breadcrumb {
    color: $font-color;
    letter-spacing: .5px;
    font-weight: 300;

    &.active {
      color: $font-color;
      font-weight: 500;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
