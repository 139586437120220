@import 'src/styles/universal/variables';

.cart--toggle {
  padding-left: 15px;
  padding-right: 15px;
}

.cart-toggle-icon {
  font-size: 24px;
  color: $dark-green;
  &:hover, &:focus {
    color: $dark-green;
  }
}
