.cart-pricing-totals {
  background-color: white;
  margin: 15px;
  border: $border;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  @include breakpoint(xs) {
    margin-bottom: 100px;
  }

  .cart-subdued {
    font-size: 12px;
    color: $color-font-lightest;
    font-weight: 400;
  }

  .cart-line-item {
    padding: 7.5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.multi-line-item {
      padding: 4.5px 15px;
    }

    &:first-child {
      padding-top: 15px;
    }

    &:last-child {
      padding-bottom: 15px;
    }

    .amount {
      text-align: right;
      font-weight: 600;
    }

    .cart-discount-code {
      color: $color-success;
      font-size: 10px;

      .remove-icon {
        color: $color-font-lightest;
        &:hover {
          color: $color-font;
        }
      }
    }

    &.big-line-item {
      font-size: 18px;
      font-weight: 600;
    }

    &.fixed-line-item {
      -webkit-backface-visibility: hidden;
      width: calc(100% - 60px);
      padding: 15px 30px;
      margin: 0;
      left: 0;
      right: 0;
      background-color: $color-success;
      color: white;
      box-shadow: $box-shadow-top;
      position: fixed;
      top: calc(100% - 51px);
      @extend .animated;

      &.hide {
        @extend .fadeOutDown;
        animation-duration: 0.1s;
      }

      &.show {
        @extend .fadeInUp;
        animation-duration: 0.1s;
      }
    }
  }


}
