/**
 *
 *  Table of Contents
 *  -----------------
 *    1. Variables
 *    2. Mixins
 *    3. Standard styles
 *    4. Errors
 *    5. Disabled
 */

/* 1. Variables */
$input-height: 40px;
$input-padding: 5px 16px;
$input-spacing: 15px;
$input-placeholder-color: #BBBBBB;
$input-error-height: 16px;
$input-error-padding: 10px;
$input-error-border-width: 1px;
$input-error-color: $red;

$input-height-small: 24px;

/* 2. Mixins for inputs */
@mixin placeholder($color) {
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color;
    opacity: 1;
  }

  &:-ms-input-placeholder { color: $color; }
  &::-ms-input-placeholder { color: $color; }
}

// Removes outside glow
input, textarea, select {
  &:focus {
    outline: 0;
  }
}

/* Standard inputs */
[class^="field"]  {
  // padding-bottom: $input-spacing;

  label { height: 15px; }

  input, select {
    -webkit-appearance: none;
    width: 100%;
    background-color: #fff;
    color: $font-color;
    height: $input-height;
    padding: $input-padding;
    border-radius: $border-radius;
    border: $border;
    transition: $transition;
    @include placeholder($input-placeholder-color);

    &:hover {
      transition: $transition;
      border: $border-darkened;
      box-shadow: $box-shadow;
    }
  }
}

input, select {
  @include breakpoint(xs) {
    font-size: 16px !important;
  }
}

select {
  // Allows spacing for icon
  padding-right: 46px;
  width: calc(100% - 30px);

  & + .anticon {
    position: absolute;
    right: 30px;
    top: calc(50% - 6px);
  }
}

// /* 4. Input with errors */
// [class^="field"] {
//   margin-bottom: calc(#{$input-error-height} + #{$input-error-padding});
//   &.has-error {
//     margin-bottom: 0;
//     input { border: $input-error-border-width solid $input-error-color; }
//     .error {
//       padding-top: $input-error-padding;
//       height: $input-error-height;
//       color: $input-error-color;
//     }
//   }
// }


/* 5. Disabled input */
input:disabled {
  background-color: rgba(0,0,0,0.05);
  border-color: transparent !important;
  box-shadow: none !important;
  color: $black !important;
  opacity: 1 !important;
}

/* 6. Sizes */
[class^="field--small"] {
  input, select {
    height: $input-height-small;
    line-height: $input-height-small;
  }
}
