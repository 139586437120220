.diagonal {
  -webkit-clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
}

$avatar-size: 50px;
.avatar {
  height: $avatar-size;
  width: $avatar-size;
  border-radius: 50px;
}


.kenburns-image {
  width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  animation: kenburns 20s ease alternate;
}

.hero-background-image {
  width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;

  background-image: url('https://natured.s3.amazonaws.com/imgix/static_ravioli.jpg');

  @media screen and (max-width: 767px) {
    $bg-color: rgba(255, 255, 255, .5);
    background-image: linear-gradient(to bottom, $bg-color , $bg-color), url('https://natured.s3.amazonaws.com/imgix/static_ravioli.jpg');
  }

  h1 {
    text-shadow: 5px 1px 15px rgba(255, 255, 255, .6);
  }
}


.cart-category-image {
  width: 100%;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}
