html {
	height: 100%;
}

body {
	color: $color-font;
	font-size: .9rem;
	font-family: $font-family-sans-serif !important;

	* {
		font-family: $font-family-sans-serif !important;
	}

	& > span {
		width: 100%;
	}
}

button, textarea, input {
	font-family: $font-family-sans-serif !important;
}
