@import 'src/styles/universal/variables';
@import 'src/styles/universal/mixins';
@import 'src/styles/universal/responsive';
@import 'src/styles/atoms/text';

$cart-img-width: 150px;
$cart-quantity-width: 80px;
$cart-product-padding: 30px;

.cart--items--section {

  @include breakpoint(md) {
    min-height: 400px;
  }

  padding-top: 30px;

  &.ui.segment { // overwrites the segment styling
    border: none;
    box-shadow: none;
  }
}

.cart--items--header {
  display: none;
  @include be-gray();
  padding-left: calc(#{$cart-img-width} + #{$cart-product-padding});
  padding-bottom: 5px;

  .qty {
    width: $cart-quantity-width;
    text-align: center;
  }

  @include breakpoint(md) {
    @include flex(center, space-between);
  }
}

.cart--items--list {
  margin-left: -15px;
  margin-right: -15px;
  @include breakpoint(md) {
    margin: auto;
  }

  .category--header {
    padding: 2rem 7.5px 0 7.5px;
    font-size: 20px;

    @include breakpoint(md) {
      padding: 2rem 0 0 0;
    }
  }

  > div:first-child .category--header {
    padding-top: 0;
  }
}

.cart--items .cart--item {
  @include flex(center, flex-start);
  padding: 7.5px;

  &.ui.segment {
    margin: 0;
    @include breakpoint(md) { padding: 0; }
    border: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: $border;
    &:first-child { border-top: $border; }

    .ui.dimmer {
      border-radius: 0 !important;
      height: calc(100% + 1px);
    }
  }

  .cart--item--image {
    img {
      width: 60px;
      height: 60px;
      border-radius: 50px;

      @include breakpoint(md) {
        border-radius: 0;
        width: $cart-img-width;
        height: auto;
        vertical-align: top;
        margin-bottom: -1px;
      }
    }
  }

  .cart--item--details {
    @include flex(flex-start, flex-start);
    width: 100%;
    padding: 0 15px;
    position: relative;
    // max-width: calc(100% - 15px - 60px - 60px);

    @include breakpoint(md) {
      width: calc(100% - #{$cart-img-width} - #{$cart-quantity-width});
      padding: 15px $cart-product-padding;
    }
    flex-direction: column;
  }

  .cart--item--product {
    color: $font-color;
    font-size: 16px;
    font-weight: 600;
  }

  .cart--item--foodmaker, .cart--item--description {
    color: $font-lightest;
    font-size: 14px;
    font-weight: 100;
  }

  .cart--item--description {
    padding-top: 5px;
    @include breakpoint(md) {
      padding-top: 15px;
    }

    font-size: 12px;
    color: $font-color;
  }

  .cart--item--quantity {
    // width: $cart-quantity-width;
    text-align: center;
    padding: 7.5px 0;
  }

  .remove-from-cart {
    padding-top: 7.5px;
  }

  .cart--item--price {
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 10px;
  }
}
