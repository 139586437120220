.nav--main {
  .nav--toggle {
    display: block;
    cursor: pointer;
  }

  .nav--categories {
    display: none;
  }
}


@media (min-width: 768px) {
  .nav--main {
    .nav--toggle {
      display: none;
    }

    .nav--categories {
      display: block;
    }
  }
}
