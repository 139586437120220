h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-sans-serif;
}

.serif-header {
  font-family: $font-family-serif;
}

.uppercase-and-spaced-text {
  font-weight: 600;
  letter-spacing: .025em;
  text-transform: uppercase;
}

.uppercase-header {
  @extend .uppercase-and-spaced-text;
  font-size: 14px;
  color: $color-font;
}

.uppercase-header-thin {
  @extend .uppercase-and-spaced-text;
  font-weight: 100;
  color: rgba($color-font, .4);
}

.uppercase-header-emph {
  @extend .uppercase-and-spaced-text;
  font-size: 11px;
  color: $color-highlight;
}

.bold-header {
  font-weight: bold;
  color: $color-font;
}

.black-header {
  font-weight: bold;
  color: $color-black;
}

.thin-subheader {
  font-weight: 300;
  margin-bottom: 0.5em;
}

// Brand logo
.brand {
  color: $color-font;
  font-weight: 600;
}

.true-header { // i.e. the biggest and boldest!
  font-size: 4rem;
  font-weight: bold;
  line-height: 1;
  font-family: "ff-meta-serif-web-pro" !important;
  // font-family: "freight-text-pro" !important;
}

.true-subheader {
  font-size: 1.55rem;
  padding-top: 45px;
  padding-bottom: 30px;
}

.little {
  font-size: .75rem;
}



.error-message {
  color: $color-highlight;
  font-size: 12px;

  &.above-button { padding-bottom: 25px; }
}

.subdued-message {
  color: rgba($color-font, .5);
  font-size: 12px;
}

.highlighted {
  padding: 2px 5px;
  background-color: rgba($color-secondary, .2);
}

.brick {
  color: $color-highlight;
}

.big-number {
  font-size: 2.5rem;
  color: rgba(0,0,0,0.25);
}

.little-light {
  color: $color-font-lightest;
  font-size: 12px;
}

.success {
  color: $green;
}

.supa-light, a.supa-light {
  color: $color-font-lightest;
}

.supa-light-sm, a.supa-light-sm {
  color: $color-font-lightest;
  font-size: 12px;
}

.supa-light-md {
  font-size: 14px;
  color: $color-font-lightest;
}



.body-paragraph {
    line-height: 2;
    padding-bottom: 10px;
}


.grayish {
  font-weight: 500;
  color: $color-font-light;
  font-size: 16px;
}


.circle-icon {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;

  &.white {
    background-color: white;
    box-shadow: $box-shadow-aggressive;
  }

  &.border {
    border: $border;
  }

  &.white-with-border {
    background-color: white;
    box-shadow: $box-shadow-aggressive;
    border: $border;
    transition: $transition;

    &:hover {
      transition: $transition;
      background-color: $color-bg-gray;
    }
  }

  &.success {
    color: white;
    background-color: $color-success;
    box-shadow: $box-shadow-aggressive;
    transition: $transition;

    &:hover {
      transition: $transition;
      color: white;
      background-color: darken($color-success, 5%);
    }
  }
}

.admin-header {
  font-weight: 600;
  font-size: 18px;
}

.admin-thin-header {
  font-weight: 400;
  font-size: 24px;
}


.super-bold {
  font-weight: 900;
  font-size: 32px;
}

.big-and-thin {
  font-size: 14px;
  font-weight: 100;
  letter-spacing: .1em;
  text-transform: uppercase;
}
