.full-screen-modal {
  margin: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100% - 30px);
  width: calc(100% - 30px);
  background-color: white;
  z-index: 101;
  animation-duration: .5s;
  padding: 15px;
  box-shadow: $box-shadow;

  .anticon-close {
    font-size: 24px;
    position: absolute;
    right: 15px;
    z-index: 11;
  }

  &.visible {
    display: block;
  }
}

$modal-max-width: 969px; // Note: same as receipts

.double-wide-modal {
  max-width: $modal-max-width;
  width: 80% !important;

  .ant-modal-body {
    display: flex;
    flex-direction: space-between;
    > * {
      flex: 1;
      padding: 30px;
    }
  }

}


.controlled-form {
  padding: 60px 30px;
  // display: flex;
  // justify-content: center;
  // .ant-form { width: 250px; }
  .ant-select-selection-selected-value { font-size: 13px; }
}

.welcome {
  margin: 30px;
  display: flex;
  align-items: center;

  .header-and-paragraph {
    display: flex;
    flex-direction: column;
  }

  h1 {
    font-weight: 800;
    font-size: 3.2em;
    color: $color-safety;
  }

  p {
    font-size: 1rem;
  }
}
