@import 'src/styles/universal/mixins';
@import 'src/styles/universal/responsive';

.cart--page {
  @include flex(flex-start, flex-start);
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 0;

  .cart-left, .cart-right {
    width: 100%;
    display: block;
  }

  .cart-right {
    padding-top: 30px;
  }

  // desktop styling
  @include breakpoint(md) {
    flex-direction: row;
    padding-top: 70px;
    padding-bottom: 5rem;

    .cart-left {
      width: 70%;
      max-width: calc(100% - 300px);
      padding-right: 30px;
    }

    .cart-right {
      padding-left: 30px;
      padding-top: 0;
      width: 30%;
      min-width: 300px;
    }
  }
}
