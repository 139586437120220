.weighted-info {
  .ant-tooltip-arrow {
    border-top-color: $color-success;
  }
  .ant-tooltip-inner {
    background-color: $color-success;
    font-size: 11px;
    text-align: center;
  }
}
