@import 'src/styles/universal/variables';
@import 'src/styles/universal/responsive';

$description-color: $font-color;

.product-page-section {
  font-size: 14px;
  font-weight: 300;

  padding: 0 15px;
  @include breakpoint(md) {
    padding: 0 20px;
  }

  > div {
    padding: 20px 0;
    border-bottom: $border-light;
  }

  .product-page--header {
    color: $font-color;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 1rem;
  }

  @include breakpoint(md) { // remove bottom borders on top
    &:last-child > div {
      border: none;
    }
  }
}
@include breakpoint(md) {
  .beneath-left .product-page-section {
    padding-left: 0;
  }

  .beneath-right .product-page-section {
    padding-right: 0;
  }
}


.product-description-section {
  margin-top: 20px;
  margin-bottom: 0;
  border-radius: 0;
  box-shadow: none;
  border-top: $border;

  > div {
    padding: 20px 0;
  }
}

$font-color: #343434;
$midnight-color: #034752;
$border-light: 1px solid rgba(0,0,0,0.05);

.product-page-section {
  font-size: 14px;
  font-weight: 300;

  .product-page--header {
    color: $font-color;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 1rem;
  }

  .emphasis {
    display: block;
    background-color: $midnight-color;
    color: white;
    border-radius: 50px;
    padding: 0 10px;
    width: fit-content;
    font-weight: 400;
    margin-bottom: 5px;
    font-size: 11px;
  }

  .quoted {
    border-left: 2px solid #cfdadd;
    padding-left: 15px;
    display: block;
  }

}
