/**
 *  Responsive breakpoints
 *    -
 */

// Breakpoint definitions
@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 414px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 415px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 992px) { @content; }
  }

  @else if $class == xl {
    @media (min-width: 1200px) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
  }
}

@mixin above($size) {
  @media (min-width: $size) { @content; }
}
