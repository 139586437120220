@mixin colorProgressBar($bar, $bg) {
  background-color: $bg;
  [class^="progress--bar"] { background-color: $bar; }
}

[class^="progress--holder"] {
  width: 100%;
  height: 5px;
  position: relative;
  box-shadow: $box-shadow;
  background-color: $light-brown-bg;

  [class^="progress--bar"] {
    position: absolute;
    height: 100%;
    background-color: $peach;
    transition: width 1s;
  }

  @include colorProgressBar($slate-gray, $light-blue);


  &[class*="--peach"] {
    @include colorProgressBar($peach, lighten($peach, 15%));
  }

  &[class*="--blue"] {
    @include colorProgressBar($light-blue, $light-blue-bg);
  }

  &[class*="--slate"] {
    @include colorProgressBar($slate-gray, $light-blue);
  }
}
