table, .ant-table {
  font-family: $font-family-sans-serif;
  font-size: 14px;

  .ant-table-thead > tr > th {
    background-color: transparent;
    @extend .uppercase-header;
    font-size: 10px;

    &.centered {
      text-align: center;
    }
  }

  tr:hover > td {
    background-color: rgba(0,0,0,0.01);
  }
}

// Only display on hover of row
.ant-table-row {
  .show-on-table-hover {
    opacity: 0;
  }
  &:hover {
    .show-on-table-hover {
      opacity: 1;
    }
  }
}

.subdued-close {
  color: rgba($color-font, .5);
  &:hover { color: $color-font; }
}

.ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 15px;
}


// Coloring for pagination numbers
.ant-pagination-item-active, .ant-pagination-item:hover, .ant-pagination-item:focus {
  border-color: $color-highlight;
  a {
    color: $color-highlight;
  }
}

// Coloring for pagination prev and next buttons
.ant-pagination-next, .ant-pagination-prev {
  &:not(.ant-pagination-disabled) {
    &:hover {
      a.ant-pagination-item-link {
        border-color: $color-highlight;
        color: $color-highlight;
      }
    }
  }
}

.small-table {
  table, .ant-table {
    font-size: 10px !important;

    thead > tr > th {
      font-weight: 500;
      font-size: 11px;
      text-transform: capitalize;
      color: $color-success;
    }

    tbody > tr:hover {
      td {
        background-color: rgba(0,0,0,0.025);
      }
    }
  }
}



tr.cross-out {
  color: $color-font-lightest;
  text-decoration: line-through;
  a, .success { color: $color-font-lightest; }
}



.table-white {
  table, .ant-table {
    background-color: white;

    .ant-table-thead th {
      text-transform: inherit;
      font-size: 13px;
    }

    .ant-table-column-sorter {
      display: none;
    }
  }
}

.table-hide-head {
  table, .ant-table {
    .ant-table-thead {
      display: none;
    }
  }
}

.table-column-title {
  display: flex;
  align-items: center;
  font-weight: 500;
  .sort-icon {
    font-size: 8px;
    color: $color-font-lightest;
  }

  &.sorted {
    color: $color-black;
    .sort-icon {
      color: $color-success;
    }
  }

}


table.transparent-table {
  background-color: transparent;

  th {
    font-weight: 500;
  }

  th, td {
    padding: 15px 0;
  }

  tr:hover {
    th, td {
      background-color: transparent;
    }
  }
}

table.natured-table {
  width: 100%;

  thead > tr > th {
    padding: 7.5px 0;
    border-bottom: $border;
  }

  tbody {
    tr > td { padding: 7.5px 0; }
    tr:first-child > td { padding-top: 15px; }
    tr:last-child > td { padding-bottom: 15px; }
  }

  &.white-table {
    background-color: white;
    box-shadow: $box-shadow;
    border: $border;
    border-radius: $border-radius;

    thead, tbody {
      tr {
        td:first-child, th:first-child {
          padding-left: 15px;
        }
        td:last-child, th:last-child {
          padding-right: 15px;
        }
      }
    }
  }
}
