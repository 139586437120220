@import 'src/styles/universal/variables';

@mixin colorProgressBar($bar, $bg) {
  background-color: $bg;
  [class^="progress--bar"] { background-color: $bar; }
}

[class^="progress--holder"] {
  width: 100%;
  height: 5px;
  position: relative;
  box-shadow: $box-shadow;
  background-color: $light-brown-bg;

  [class^="progress--bar"] {
    position: absolute;
    height: 100%;
    background-color: $peach;
    transition: width 1s;
  }

  @include colorProgressBar($slate-gray, $light-blue);

  &[class*="--highlight"] {
    @include colorProgressBar($highlight-color, lighten($highlight-color, 15%));
  }

  &[class*="--primary"] {
    @include colorProgressBar($primary-color, lighten($primary-color, 25%));
  }

  &[class*="--peach"] {
    @include colorProgressBar($peach, lighten($peach, 15%));
  }

  &[class*="--orange"] {
    @include colorProgressBar($orange, lighten($orange, 20%));
  }

  &[class*="--blue"] {
    @include colorProgressBar($light-blue, $light-blue-bg);
  }

  &[class*="--slate"] {
    @include colorProgressBar($slate-gray, $light-blue);
  }

  &[class*="--green"] {
    @include colorProgressBar($bright-green, $light-blue);
  }
}
