.browse-all-categories {
  margin-top: 5px;
  max-height: 25vh;
  width: 100%;
  position: relative;
  overflow: hidden;

  .browse-name {
    position: absolute;
      // left: 15px;
      // bottom: 15px;
    font-weight: bold;
    font-size: 20px;

    position: absolute;
    width: 100%;
    text-align: center;
    height: 100%;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 36px;
    color: white;

    span {
      background-color: rgba(0,0,0,0.25);
      padding: 0 15px;
      border-radius: $border-radius;
    }
  }

  .browse-img {
    margin-top: -50px;
    width: 100%;
  }
}
