
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $color-secondary;
}

.ant-checkbox-wrapper.has-error .ant-checkbox-inner {
    border-color: $color-highlight;
    animation-name: smallShake;
    animation-duration: 1s;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: $color-secondary;
  border-color: $color-secondary;
}

.ant-checkbox-checked:after {
  border: 1px solid $color-secondary;
}
