.payment-cards-list {

  .card-list-item {
    .ant-list-item-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .ant-list-item {
    @media screen and (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .card-details {
    display: flex;
    align-items: center;

    .default-card-tag {
      min-width: 100px;
      text-align: center;
      display: block;

      // Hide this on mobile
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .payment-card {
      padding-right: 15px;

      @media screen and (max-width: 767px) {
        padding-left: 0;
      }
    }
  }

  .card-actions {

    @media screen and (max-width: 767px) {
      padding-top: 10px;
    }

    .actions { display: flex; }

    // Removes spacing on tag
    .ant-tag {
      margin: 0;
    }

    .tag:not(:first-child) {
      margin-left: 10px;
    }

    .action-ellipsis {
      font-weight: bold;
      padding: 5px;
      font-size: 24px;
    }
  }
}
