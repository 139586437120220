@import 'src/styles/universal/variables';
@import 'src/styles/universal/mixins';

.zip-check {
  @include flex(flex-start, center);
  flex-direction: column;
  margin: 30px auto;
  max-width: 380px;

  .input-and-button {
    max-width: 300px;
    padding-top: 2rem;
    margin: auto;

    .button--slate-gray--reg {
      font-weight: 500;
    }
  }

  .complete-account-form {
    padding-top: 1rem;
    width: 100%;
    text-align: left;
    margin-bottom: 45px;
  }

  h1, .header--2 {
    font-family: p22-mackinac-pro, serif !important;
    color: $primary-color;
    font-size: 2.2rem !important;
    // line-height: 1.05;
    // margin: 0 0 1.2em 0;
  }

  .para--16 {
    text-align: center;
    max-width: 400px;
    margin: auto;
    font-weight: 300;
  }

  .continue-button {
    margin: 45px auto;
    // @include flex(center, center);
  }
}
