@import 'src/styles/universal/variables';
@import 'src/styles/universal/mixins';
@import 'src/styles/universal/responsive';

.basket--header {
  text-align: center;
  padding: 30px 0;
  background-color: $midnight-color;
  border-radius: $border-radius;
  color: white;

  .cart--title {
    color: white;
    font-weight: bold;
  }

  .delivery--title {
    @include flex(center, center);
    flex-direction: column;
  }

  .delivery-day, .delivery-window {
    font-size: 16px;
    font-weight: 300;
  }

  @include breakpoint(md) {
    border-radius: 0;
    @include flex(center, space-between);
    border-bottom: $border;
    > div { width: fit-content; }
    padding: 0;
    background-color: transparent;
    color: $font-color;

    .delivery-day, .delivery-window {
      padding-bottom: 2.5px;
      width: max-content;
      padding: 0;
    }

    .cart--title {
      padding-right: 0;
      padding-bottom: 10px;
      max-width: 200px;
    }

    .delivery--title {
      @include flex(flex-end, center);
      flex-direction: column;
      min-width: max-content;
      min-width: calc(100% - 200px);
    }

    .cart--title {
      color: $font-color;
      margin-bottom: 0;
    }

    &:last-child {
      border-bottom: $border;
    }
  }

  @include breakpoint(xl) {
    .delivery-day {
      border-right: $border-darkened;
    }

    .delivery-day, .delivery-window {
      font-size: 16px;
      padding: 0 3%;
    }

    .delivery-window {
      padding-right: 0;
    }

    .cart--title {
      padding-right: 3%;
    }

    .delivery--title {
      @include flex(center, flex-end);
      flex-direction: row;
    }
  }
}

.checkout-banner {
  background-color: $gray-background-color;
  margin-top: 2rem;
  border-radius: $border-radius;
  .icon { color: $error-color; }
  padding: 7.5px 15px;
  > span { padding-left: 15px; }
  @include flex(center, space-between);

  @include breakpoint(md) {
    justify-content: flex-start;
    margin-top: 5px;
    padding: 7.5px 10px;
    > span { padding-left: 10px; }
  }

}
