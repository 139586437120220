section.hero {
  padding-top: 3rem;
  padding-bottom: 3rem;

  &.banner {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  @media screen and (min-width: 48rem) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  &.subdued {
    background-color: $color-subdued;
    & + section { border-top: none; }
  }

  .hero-content {
    @extend .content-container;
    &.centered { text-align: center; }
  }

  .hero-image {
    padding: 60px;
    border-radius: $border-radius;
    background-size: cover !important;

    @media screen and (max-width: 767px) {
      padding: 15px;
    }
  }

  .hero-header {
    @extend .serif-header;
    margin-bottom: 2rem;
  }

  .hero-headline {
    @extend .serif-header;
    font-size: 2rem;
    margin-top: 0;

    @media screen and (min-width: 768px) {
      max-width: 14em;
    }

    @media screen and (min-width: 25rem) {
      font-size: calc(2rem + (12 * (100vw - 400px)/ 624));
    }

    @media screen and (min-width: 48rem) {
      margin: 0 0 1.5rem;
    }
  }

  .hero-paragraph {
    margin: 1.5rem 0;
    font-size: 18px;
    line-height: 36px;

    @media screen and (min-width: 768px) {
      max-width: 50%;
      font-size: 22px;
      margin: 0 0 3.5rem;
    }


    &.centered {
      max-width: 50%;
      margin: auto;

      @media screen and (max-width: 767px) {
        max-width: 100%;
      }
    }
  }

  .hero-button {
    @extend .oversized-btn;
    transition: $transition;
    &:hover { transition: $transition; }

    span + .anticon {
      padding-left: 30px;
    }

    &.green {
      color: $color-success;
      border: 1px solid $color-success;
      background-color: white;

      &:hover {
        border-color: darken($color-success, 3%);
        color: darken($color-success, 5%);
        box-shadow: $box-shadow-aggressive;
      }
    }
  }
}

.hero.banner {
  .hero-content {
    @extend .content-container;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .banner-header {
      @extend .serif-header;
      margin-bottom: 0;
    }

    .subdued {
      color: $color-font-light;
    }
  }
}


.market-home {
  section.hero {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    &:first-child {
      padding-top: 5rem;
    }

    &:last-child {
      padding-bottom: 5rem;
    }

    @media screen and (max-width: 767px) {
      padding-top: 1rem;
      padding-bottom: 1rem;
      &:first-child { padding-top: 1.5rem; }

    }
  }
}
